import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/league/';

class LeagueService {
  getLeagueInfo(leagueId){
    return axios.get(API_URL + `leagueInfo?id=${leagueId}`, { headers: authHeader()});
  }

  get1v1LeagueInfo(leagueId){
    return axios.get(API_URL + `1v1leagueInfo?id=${leagueId}`, { headers: authHeader()});
  }

  getAllLeagues(){
    return axios.get(API_URL + `allLeagues`);
  }

  joinCurrentLeague(user_id){
    return axios.post(API_URL + 'joinLeague', {user_id: user_id}, { headers: authHeader()});
  }

  leaveCurrentLeague(user_id){
    return axios.post(API_URL + 'leaveLeague', {user_id: user_id}, { headers: authHeader()});
  }

  startLeague(options){
    return axios.post(API_URL + 'startLeague', {options}, { headers: authHeader()});
  }

}

export default new LeagueService();